import React from "react";
import { Link } from "gatsby";
import { RichText } from "prismic-reactjs";
import slugify from "slugify";
import FontAwesome from "react-fontawesome";
import PlayIcon from "@images/ui-play.png";
import styles from "./youtube-video.module.scss";

const ExternalLinkFrame = ({ video, tags }) => {
  const {
    node: { title, external_link, thumbnail }
  } = video;
  return (
    <div>
        <div className={styles.videosItemThumbWrap}>
          {/* {tags && (
            <div className={styles.videosItemTags}>
              {tags.map((tag, index) => {
                const slug = slugify(tag).toLowerCase();
                return (
                  <Link to={`/tag/${slug}/`} key={index}>
                    <span key={index} title={tag}>
                      {tag}
                    </span>
                  </Link>
                );
              })}
            </div>
          )} */}
          <div className={styles.videosItemPlay}>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={external_link.url}
            >
              <img src={PlayIcon} draggable="false"/>
            </a>
          </div>
          <a
              target="_blank"
              rel="noopener noreferrer"
              href={external_link.url}
            >
            <img
                draggable="false"
                alt={RichText.asText(title)}
                title={RichText.asText(title)}
                src={thumbnail.url}
            />
          </a>
        </div>
        <h4>{RichText.asText(title)}</h4>
    </div>
  );
};

export default ExternalLinkFrame;
