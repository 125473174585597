import React, { useContext } from "react"
import { Link } from "gatsby";
import { RichText } from "prismic-reactjs";
import { StoreContext } from "@Context/store-context.js";
import FontAwesome from "react-fontawesome";
import styles from "./index.module.scss";

const Cart = ({data}) => {
    const { primary } = data;
    const context = useContext(StoreContext);
    const { checkout } = context;
    const { lineItems } = checkout;
    const cartActive = true;
    return(<section className={styles.cart}>
            <div className={styles.cartContent}>
                <div className={styles.cartContentInner}>
                    {RichText.render(primary.headline)}
                    <div className={styles.cartDetails}>
                        <ul className={styles.cartDetailsHeader}>
                            <li className={styles.cartDetailsHeaderSpacer}/>
                            <li className={styles.cartDetailsHeaderPrice}>Price</li>
                            <li className={styles.cartDetailsHeaderQuantity}>Quantity</li>
                            <li className={styles.cartDetailsHeaderTotal}>Total</li>
                        </ul>
                        {lineItems && lineItems.map((item, index) => {
                            return(<div key={index}>
                                <ul className={styles.cartDetailsBody}>
                                    <li className={styles.cartDetailsBodyTitle}>
                                        <div className={styles.cartDetailsBodyTitleImage}>
                                            <>
                                                <img
                                                draggable={false}
                                                src={item.variant.image.src}
                                                alt={item.variant.title}
                                                title={item.variant.title}
                                                />
                                            </>
                                            <Link to={`/shop/${item.variant.product.handle}`}>
                                                <h3>{item.title}<span>{item.variant.title}</span></h3>
                                            </Link>
                                        </div>
                                    </li>
                                    <li className={styles.cartDetailsHeaderPrice}>${item.variant.price}</li>
                                    <li className={styles.cartDetailsHeaderQuantity}>
                                        <>{item.quantity}</>
                                    </li>
                                    <li className={styles.cartDetailsHeaderTotal}>
                                        <>${item.quantity * item.variant.price}</>
                                        <button
                                            onClick={() => {
                                                context.removeProductFromCart(
                                                    item.id
                                                )
                                            }}
                                            className={styles.cartDetailsRemove}
                                        >
                                            <FontAwesome name="trash"/>
                                        </button>
                                    </li>
                                </ul>
                            </div>)
                        })}
                        {lineItems.length === 0 && <div className={styles.cartDetailsEmpty}>Your cart is empty</div>}
                        <ul className={styles.cartDetailsFooter}>
                            <li className={styles.cartDetailsFooterSpacer}/>
                            <li className={styles.cartDetailsFooterQuantity}>Subtotal</li>
                            <li className={styles.cartDetailsFooterTotal}>{checkout.subtotalPrice
                              ? `$${checkout.subtotalPrice}`
                              : "$0.00"}</li>
                        </ul>
                        <ul className={styles.cartDetailsFooterActions}>
                        {cartActive && (
                            <button
                              onClick={() => {
                                window.open(checkout.webUrl);
                              }}
                              className="cart__inner--action-checkout shimmer-pink"
                            >
                              Go to Checkout
                            </button>
                          )}
                        </ul>
                    </div>
                </div>
            </div>
        </section>)
}

export default Cart;