import React, { Component } from "react";
import Subscribe from "@components/subscribe";
import ProductsGrid from "@components/products-grid";
import Spacer from "@components/spacer";
import Image from "@components/image";
import Photos from "@components/photos";
import Contact from "@components/contact";
import Tour from "@components/tour";
import Marquee from "@components/marquee";
import Text from "@components/text";
import Videos from "@components/videos";
import Header from "@components/header";
import Cart from "@components/cart";
import TwoColumnCTA from "@components/two-column-cta";

import { get } from "lodash";

const AddOnModule = ({index}) => {
    if( index === 1 ){
        return <Header/>
    }else{
        return(<span/>)
    }
}

class ModuleRenderer extends Component {
    render(){
        const { modules } = this.props;
        return(
            <>
            {modules &&
                modules.map((m, index) => {
                    switch (m.type) {

                        case "cart":
                            return <Cart data={m} key={index}/>
                        case "subscribe":
                            return <React.Fragment key={index}>
                                    <Subscribe data={m} />
                                    <AddOnModule index={index}/>
                                    </React.Fragment>
                        case "tour":
                            return <Tour data={m} key={index} />
                        case "two_column_cta":
                            return <TwoColumnCTA data={m} key={index}/>
                        case "contact":
                            return <React.Fragment key={index}>
                                    <Contact data={m} key={index} />
                                    <AddOnModule index={index}/>
                                    </React.Fragment>
                        case "image":
                            return <React.Fragment key={index}>
                                    <Image data={m} key={index} />
                                    <AddOnModule index={index}/>
                                    </React.Fragment>
                        case "marquee":
                            return <React.Fragment key={index}>
                                    <Marquee data={m} key={index} />
                                    <AddOnModule index={index}/>
                                    </React.Fragment>
                        case "photos":
                            return <React.Fragment key={index}>
                                    <Photos data={m} key={index} />
                                    <AddOnModule index={index}/>
                                    </React.Fragment>
                        case "text":
                            return <React.Fragment key={index}>
                                    <Text data={m} key={index} />
                                    <AddOnModule index={index}/>
                                    </React.Fragment>
                        case "videos":
                            return <React.Fragment key={index}>
                                    <Videos data={m} key={index} />
                                    <AddOnModule index={index}/>
                                    </React.Fragment>
                        case "products_grid":
                            return <React.Fragment key={index}>
                                    <ProductsGrid data={m} key={index} />
                                    <AddOnModule index={index}/>
                                    </React.Fragment>
                        case "spacer":
                            return <React.Fragment key={index}>
                                    <Spacer data={m} key={index} />
                                    <AddOnModule index={index}/>
                                    </React.Fragment>
                        default:
                        return null;
                    }
                })}
        </>
        )
    }
}

export default ModuleRenderer;