import React, { Component } from "react";
import styles from "./index.module.scss";
import { RichText } from "prismic-reactjs";
import addToMailchimp from "gatsby-plugin-mailchimp";

class Field extends Component {
    constructor(props){
        super(props);
        this.state = {
            labelVisible: true,
            value: '',
        }
        this.input = null;
        this.hideLabel = this.hideLabel.bind(this);
        this.showLabel = this.showLabel.bind(this);
        this.updateValue = this.updateValue.bind(this);
    }
    hideLabel(){
        this.setState({ labelVisible: false });
    }
    showLabel(){
        const { value } = this.state;
        if( value !== "" ){ return }
        this.setState({ labelVisible: true });
    }
    updateValue(e){
        const { onFieldChange } = this.props;
        this.setState({ value: e.target.value });        
        onFieldChange(e);
    }
    componentDidMount(){
        this.input.addEventListener("focus", this.hideLabel)
        this.input.addEventListener("blur", this.showLabel)
    }
    componentWillUnmount(){
        this.input.removeEventListener("focus", this.hideLabel)
        this.input.removeEventListener("blur", this.showLabel)
    }
    render(){
        const { id, type, label } = this.props;
        const { labelVisible } = this.state;
        const labelClasses = labelVisible ? styles.visible : styles.hidden;
        return (<div className={styles.subscribeFormField}>
                    <input id={id} name={id} type={type} ref={i => (this.input = i)} onChange={this.updateValue} />
                    <label htmlFor={id} className={labelClasses}>{label}</label>
                </div>)
    }
}

class Subscribe extends Component {
    constructor(props){
        super(props);
        this.state = {
            fname: '',
            lname: '',
            email: '',
            response: null,
            success: null,
            error: null,
        }
        this.updateValue = this.updateValue.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }
    updateValue(e){
        this.setState({ [e.target.id]: e.target.value });
    }

    handleSubmit = async (e) => {
        e.preventDefault();
        const { email, fname, lname } = this.state;

        addToMailchimp(email, { FNAME: fname, LNAME: lname })
        .then(data => {

            const { msg, result } = data;
            if( result === "success" ){
                this.setState({ response: msg, success: true })
            }else{
                this.setState({ response: 'You\`re already subscribed.', error: true })
            }

        })
        .catch(() => {
            this.setState({ response: 'Something went wrong...', error: true })
        })


    }

    // }
    render(){
        const { data } = this.props;
        const { response } = this.state;
        const { primary } = data;
        const { instructions, call_to_action_text } = primary;

        
        return(<section className={styles.subscribe}>
            <div className={styles.subscribeInner}>
                {primary.headline && <>{RichText.render(primary.headline)}</>}
                <form className={styles.subscribeForm} onSubmit={this.handleSubmit}>
                    <Field id="fname" label="First Name" type="text" onFieldChange={this.updateValue} />
                    <Field id="lname" label="Last Name" type="text" onFieldChange={this.updateValue} />
                    <Field id="email" label="E-mail" type="email" onFieldChange={this.updateValue} />
                    <div className={`${styles.subscribeFormField} ${styles.subscribeFormFieldSubmit}`}>
                        <button>{call_to_action_text}</button>
                    </div>
                </form>
                <div className={styles.subscribeFormResponse}>
                {response ? (<>{response}</>) : (<>{RichText.render(instructions)}</>)}
                </div>
            </div>
        </section>)
    }
}

export default Subscribe;