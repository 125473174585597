import React from "react";
import { StaticQuery } from "gatsby";
import { graphql } from "gatsby";
import { RichText } from "prismic-reactjs";
import { get } from "lodash";
import moment from "moment";
import styles from "./index.module.scss";

const Tour = ({ data }) => {
    const { primary: { headline, description } } = data;
    return(<StaticQuery
      query={graphql`
      query tourQuery {
        prismic {
          allEvents(sortBy: date_ASC) {
            edges {
              node {
                title
                venue
                status
                location
                link {
                  ... on PRISMIC__ExternalLink {
                    _linkType
                    url
                  }
                }
                date
                _meta {
                  uid
                }
              }
            }
          }
        }
    }
      `}
      render={toursData => {
        const events = get(toursData, "prismic.allEvents.edges", []);
        const eventsFiltered = events && events.filter((event) => {
          const { node } = event;
            if (
              moment(node.date) > moment()
            ) {
              return true;
            }
        });
        return(
      <>
        {eventsFiltered.length > 0 && <section className={styles.tour} id="tour">
            <div className={styles.tourInner}>
            {RichText.render(headline)}
            {RichText.render(description)}
            </div>
            <ol className={styles.tourList}>
                {eventsFiltered && eventsFiltered.map((event, index) => {
                    const { node } = event;                    
                    const buttonText = node.status === "Available" ? "Get Tickets Now!" : node.status;
                    return(<li key={index} className={styles.tourItem}>
                        <div className={styles.tourItemInner}>
                          <h3>{RichText.asText(node.title)}</h3>
                          <h4>{node.location}<br/>{node.venue}</h4>
                          <time>{moment(node.date).format("MMM")} {moment(node.date).format("D")} {moment(node.date).format("YYYY")}</time>
                          {node.link && <a className={styles.tourItemButton} href={node.link.url}>{buttonText}</a>}
                        </div>
                        {node.status === "Sold Out" ? <div className={styles.tourItemSoldOut}><span>Sold Out</span></div> : null}
                    </li>)
                })}
            </ol>
        </section>}
    </>)}}/>)
}

export default Tour;