import React from "react";
import { Link, StaticQuery, graphql } from "gatsby";
import { RichText } from "prismic-reactjs";
import { get } from "lodash";
import slugify from "slugify";
import YouTubeVideoFrame from "./youtube-video.jsx";
import ExternalVideo from "./external-video.jsx";
import styles from "./index.module.scss";

const Videos = ({ data }) => {
    const { primary: { headline, description } } = data;
    return(<StaticQuery
      query={graphql`
      query videoQuery {
        prismic {
          allVideos(sortBy: meta_firstPublicationDate_DESC) {
            edges {
              node {
                title
                youtube_id
                thumbnail
                external_link {
                ... on PRISMIC__ExternalLink {
                    _linkType
                    url
                  }
                }
                _meta {
                  uid
                  tags
                }
              }
            }
          }
        }
      }
      `}
      render={videosData => {
        const videos = get(videosData, "prismic.allVideos.edges", []);
        return(
    <>
        <section className={styles.videos} id="videos">
            <div className={styles.videosInner}>
              <h2>{RichText.asText(headline)}</h2>
              {RichText.render(description)}
            </div>
            <ul className={styles.videosList}>
                {videos && videos.map((video, index) => {
                    const { node: { external_link, _meta: { tags } } } = video;
                    return(<li key={index} className={styles.videosItem}>
                              {external_link ? <ExternalVideo video={video} tags={tags} /> : <YouTubeVideoFrame video={video} tags={tags}/>}
                          </li>)
                })}
            </ul>
        </section>
    </>)}}/>)
}

export default Videos;