import React from "react";
import Slider from "react-slick";
import { RichText } from "prismic-reactjs";
import styles from "./index.module.scss";
import "./slick.scss";

const Photos = ({ data }) => {
    const { fields, primary } = data;
    const carouselSettings = {
        speed: 500,
        slidesToShow: 2.25,
        slidesToScroll: 1,
        infinite: false,
        centerMode: true,
        initialSlide: 0,
        variableWidth: true,
        arrows: false,
    };
    return(<>
        <section className={styles.photoGallery} id="photos">
            <Slider {...carouselSettings}>
            <div className="home__photos--item home__photos--item-start">
              <div className="home__photos--item-start--inner">
                {RichText.render(primary.headline)}
                <span>
                  {/* <img
                    draggable={false}
                    className="left-arrow"
                    src={uiLeftArrow}
                    alt="Arrow graphic"
                  /> */}
                  {RichText.asText(primary.instruction_text)}
                </span>
              </div>
            </div>
            {fields && fields.map((field, index) => {
                const { image } = field;
                return(<>
                        <div className={styles.photoGalleryItem} key={index}>
                            <img src={image.url}/>
                        </div>
                      </>)
            })}
          </Slider>
        </section>
    </>)
}

export default Photos;