import React from "react";
import { RichText } from "prismic-reactjs";
import slugify from "slugify";
import styles from "./index.module.scss";

const Contact = ({ data }) => {
  const { primary, fields } = data;
  var contactsFiltered = {};
  fields &&
    fields.forEach((contact, idx) => {
      const subject = slugify(RichText.asText(contact.subject)).toLowerCase();
      if (typeof contactsFiltered[subject] != "undefined") {
        contactsFiltered[subject].push({ contact });
      } else {
        contactsFiltered[subject] = [{ contact }];
      }
    });
  return (
    <section className={styles.contact}>
      <div className={styles.contactContent}>
        <div className={styles.contactContentInner}>
          {RichText.render(primary.headline)}
          {contactsFiltered &&
            Object.keys(contactsFiltered).map((c, idx) => {
              const contactGroup = contactsFiltered[c];
              return (
                <>
                  <h4>{RichText.asText(contactGroup[0].contact.subject)}</h4>
                  <ul className={styles.contactContentList}>
                    {contactGroup.map((contactItem, index) => {
                      const { contact } = contactItem;
                      return (
                        <li key={index}>
                          {contact.link ? (
                            <a
                              target={contact.link.target}
                              href={contact.link.url}
                            >
                              <div className="name">
                                <h5>{RichText.asText(contact.name)}</h5>
                              </div>
                            </a>
                          ) : (
                            <div className="name">
                              <h5>{RichText.asText(contact.name)}</h5>
                            </div>
                          )}
                          {contact.email && (
                            <div className="email">
                              <a
                                href={`mailto:${RichText.asText(
                                  contact.email
                                )}`}
                              >
                                {RichText.asText(contact.email)}
                              </a>
                            </div>
                          )}
                        </li>
                      );
                    })}
                  </ul>
                </>
              );
            })}
        </div>
      </div>
    </section>
  );
};

export default Contact;
