import React from "react";
import Layout from "@components/layout";
import SEO from "@components/seo";
import Header from "@components/header";
import ModuleRenderer from "@components/module-renderer";
import { get } from "lodash";
import { graphql } from "gatsby";
// import styles from "./../scss/home.module.scss";

export const query = graphql`
  query PrismicPageQuery($uid: String) {
    prismic {
      allPages(uid: $uid, lang: "en-us") {
        edges {
          node {
            body {
              ... on PRISMIC_PageBodyTwo_column_cta {
                type
                label
                primary {
                  headline
                  left_text
                  right_text
                  cta_link {
                    ... on PRISMIC__ExternalLink {
                      _linkType
                      url
                    }
                  }
                  cta
                }
              }
              ... on PRISMIC_PageBodyImage {
                type
                label
                primary {
                  image
                }
              }
              ... on PRISMIC_PageBodyTour {
                type
                label
                primary {
                  description
                  headline
                }
              }
              ... on PRISMIC_PageBodyStore {
                type
                label
                primary {
                  headline
                  description
                }
              }
              ... on PRISMIC_PageBodyProducts_grid {
                type
                label
                primary {
                  description
                  headline
                }
              }
              ... on PRISMIC_PageBodySubscribe {
                type
                label
                primary {
                  instructions
                  call_to_action_text
                  headline
                }
              }
              ... on PRISMIC_PageBodyContact {
                type
                label
                fields {
                  email
                  link {
                    ... on PRISMIC__ExternalLink {
                      _linkType
                      url
                    }
                  }
                  name
                  subject
                }
                primary {
                  headline
                }
              }
              ... on PRISMIC_PageBodyText {
                type
                label
                primary {
                  headline
                  content
                }
              }
              ... on PRISMIC_PageBodyMarquee {
                type
                label
                primary {
                  image
                  primary_headline
                  secondary_headline
                }
              }
              ... on PRISMIC_PageBodyVideos {
                type
                label
                primary {
                  description
                  headline
                }
              }
              ... on PRISMIC_PageBodyCart {
                type
                label
                primary {
                  headline
                }
              }
              ... on PRISMIC_PageBodySpacer {
                type
                label
              }
              ... on PRISMIC_PageBodyPhotos {
                type
                label
                fields {
                  image
                }
                primary {
                  instruction_text
                  headline
                }
              }
            }
          }
        }
      }
    }
  }
`;

const TemplatePage = ({ data }) => {
  const modules = get(data, "prismic.allPages.edges[0].node.body");
  return (
    <Layout>
      <SEO title="Home" />
      <Header isInterior={true} />
      <ModuleRenderer modules={modules} />
    </Layout>
  );
};

export default TemplatePage;
