import React from "react";
import { RichText } from "prismic-reactjs";
import slugify from "slugify";
import styles from "./index.module.scss";

const Text = ({ data }) => {
    const { primary: { headline, content } } = data;
    const id = slugify(RichText.asText(headline)).toLowerCase();
    return(<section className={styles.text} id={id}>
                <div className={styles.textInner}>
                    {headline && <h2>{RichText.asText(headline)}</h2>}
                    {content && (<div className={styles.textInnerContent}>
                        {<>{RichText.render(content)}</>}
                    </div>)}
                </div>
            </section>)
}

export default Text;