import React, { useRef } from "react";
import { StaticQuery, graphql, Link } from "gatsby";
import { RichText } from "prismic-reactjs";
import MagicGrid from "magic-grid-react"
import { get } from "lodash";
import styles from "./index.module.scss";

const ProductsGrid = ({ data }) => {
    const { primary: { headline, description } } = data;
    const storeUrl = "shop";
    var shopGrid = useRef();
    const handleImageLoad = () => {
      setTimeout(() => {
        shopGrid.positionItems()
      }, 100)
    }
    return(<StaticQuery
      query={graphql`
      query productQuery {
        allShopifyProduct {
          edges {
            node {
              id
              title
              handle
              tags
              priceRange {
                minVariantPrice {
                  amount
                  currencyCode
                }
                maxVariantPrice {
                  amount
                  currencyCode
                }
              }
              variants {
                id
                title
                price
                availableForSale
                shopifyId
              }
              images {
                originalSrc
              }
            }
          }
        }
      }
      `}
      render={productData => {
        const products = get(productData, "allShopifyProduct.edges", []);
        return(
          <>
            <section className={styles.products} id="shop">
                <div className={styles.productsInner}>
                  <h2>{RichText.asText(headline)}</h2>
                  {RichText.render(description)}
                </div>
                <ul className={styles.productsList}>
                  <MagicGrid
                    animate={true}
                    ref={element => {
                      shopGrid = element
                    }}
                  >
                    {products && products.map((product, index) => {
                        const { node: { title, images, handle, priceRange, tags } } = product;
                        const isPreSale = false; //tags.indexOf("presale") > -1
                        return(<li key={index} className={styles.productsItem}>
                          <Link to={`/${storeUrl}/${handle}`}>
                            <>
                            <div className={styles.productsItemImageWrap}>
                              {isPreSale && (<div className={styles.productsItemImageWrapPreSale}><span>Presale</span></div>)}
                              {images.length > 0 && (<img src={images[0].originalSrc} onLoad={handleImageLoad}/>)}
                            </div>
                            <h3><>
                            {title}
                            {priceRange && <><br/><span>${parseFloat(priceRange.minVariantPrice.amount, 2).toFixed(2)}</span></>}
                            </>
                            </h3>
                            </>
                          </Link>
                        </li>)
                    })}
                  </MagicGrid>
                </ul>
            </section>
        </>)}
    }/>
    )
}

export default ProductsGrid;