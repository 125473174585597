import React from "react";
import styles from "./index.module.scss";

const Image = ({ data }) => {
    const { primary: { image } } = data;
    return(<>
        {image && <img draggable="false" className={styles.imageElement} alt={image.alt} src={image.url} />}
    </>)
}

export default Image;