import React from "react";
import { RichText } from "prismic-reactjs";
import { get } from "lodash";
import { Controller, Scene } from "react-scrollmagic";
import Img from "gatsby-image";
import { Tween } from "react-gsap";
import styles from "./index.module.scss";

const Marquee = ({ data }) => {
    const { primary: { primary_headline, secondary_headline, image, imageSharp } } = data;
    const mobile = get(image, "Mobile");
    const tablet = get(image, "Tablet");
    console.log(imageSharp);
    return(<section id="marquee" className={styles.marquee}>
            <Controller>
                <>
                <Scene
                  triggerElement={`#marquee-trigger`}
                  duration="50%"
                >
                  {progress => {
                    return (
                      <Tween
                        from={{ css: { bottom: `30px` } }}
                        to={{ css: { bottom: `90px` } }}
                        totalProgress={progress}
                        paused
                      >
                        <>
                        <div className={styles.marqueeCallOut}>
                          <a href="https://www.bbc.co.uk/iplayer/episodes/p07kt8b4/rupauls-drag-race-uk" target="_blank">
                            <div className={styles.marqueeCallOutInner}>Featured on<br/>RuPaul's Drag Race<br/>UK FOR BBC</div>
                          </a>
                        </div>
                        <div className={styles.marqueeText}>
                            <React.Fragment>
                                {primary_headline && <h2>{RichText.asText(primary_headline)}</h2>}
                                {secondary_headline && <h3>{RichText.asText(secondary_headline)}</h3>}
                            </React.Fragment>
                        </div>
                        </>
                        </Tween>)}}
                </Scene>
                {/* {image && <img className={styles.desktop} src={image.url}/>} */}
                {imageSharp && <Img
                                alt={imageSharp.alt}
                                className={styles.desktop}
                                fluid={imageSharp.childImageSharp.fluid}
                            />}
                {mobile && <img className={styles.mobile} src={mobile.url}/>}
                {tablet && <img className={styles.tablet} src={tablet.url}/>}
                </>
                </Controller>
            </section>)
}

export default Marquee;