import React from "react";
import { RichText } from "prismic-reactjs";
import slugify from "slugify";
import styles from "./index.module.scss";

const TwoColumnCta = ({ data }) => {
    const { primary: { headline, left_text, right_text, cta, cta_link } } = data;
    const id = slugify(RichText.asText(headline)).toLowerCase();
    return(<section className={styles.twoColumnCta} id={id}>
                {headline && <h2>{RichText.asText(headline)}</h2>}
                <div className={styles.twoColumnCtaInner}>
                    {left_text && (<div className={styles.twoColumnCtaLeft}>
                        <div className={styles.twoColumnCtaCenter}>
                        {<>{RichText.render(left_text)}</>}
                        {cta && (<div className={styles.twoColumnCtaActions}><a target="_blank" href={cta_link.url}>{RichText.asText(cta)}</a></div>)}
                        </div>
                    </div>)}
                    {right_text && (<div className={styles.twoColumnCtaRight}>
                        {<>{RichText.render(right_text)}</>}
                    </div>)}
                </div>
            </section>)
}

export default TwoColumnCta;